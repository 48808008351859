<template>
  <div class="FooterBar" id="footPop">
    <div class="bg_p">
      <div class="hid_div app_common_lb flex_style">
        <div>
          <div @mouseover="tabId=1" @mouseleave="tabId=-1" class="dia_div" v-show="tabId==1">
            <div class="dia_item"><a href="https://www.mee.gov.cn/" target="_blank">生态环境部</a></div>
            <div class="dia_item"><a href="http://www.sd.gov.cn/" target="_blank">山东省人民政府</a></div>
            <div class="dia_item"><a href="http://sthj.shandong.gov.cn/" target="_blank">省生态境厅</a></div>
            <div class="dia_item"><a href="http://fgw.shandong.gov.cn/" target="_blank">省发展改革委员会</a></div>
            <div class="dia_item"><a href="http://kjt.shandong.gov.cn/" target="_blank">省科技厅</a></div>
            <div class="dia_item"><a href="http://gxt.shandong.gov.cn/" target="_blank">省工业和信息化厅</a></div>
            <div class="dia_item"><a href="http://czt.shandong.gov.cn/" target="_blank">省财政厅</a></div>
            <div class="dia_item"><a href="http://hrss.shandong.gov.cn/" target="_blank">省人力资源社会保障厅</a></div>
            <div class="dia_item"><a href="http://nync.shandong.gov.cn/" target="_blank">省农业农村厅</a></div>
            <div class="dia_item"><a href="http://commerce.shandong.gov.cn/" target="_blank">省商务厅</a></div>
            <div class="dia_item"><a href="http://dfjrjgj.shandong.gov.cn/" target="_blank">省地方金融监管局</a></div>
            <div class="dia_item"><a href="http://jinan.pbc.gov.cn/" target="_blank">人民银行济南分行</a></div>
          </div>
          <div class="dia_title" @mouseover="tabId=1" @mouseleave="tabId=-1">政府网站 <span>▲</span></div>
        </div>
        <div>
          <div @mouseover="tabId=2" @mouseleave="tabId=-1" class="dia_div1" v-show="tabId==2">
            <div class="dia_item"><a href="http://www.caep.org.cn/" target="_blank">生态环境部环境规划院</a></div>
            <div class="dia_item"><a href="http://www.sdaep.cn/" target="_blank">山东省生态环境规划研究院</a></div>
            <div class="dia_item"><a href="https://www.ceett.org.cn/huanbao/home/index" target="_blank">国家生态环境科技成果转化综合服务平台</a></div>
            <div class="dia_item"><a href="http://www.caepi.org.cn/" target="_blank">中国环境保护产业协会</a></div>
            <div class="dia_item"><a href="http://www.chinacses.org/" target="_blank">中国环境科学学会</a></div>
            <div class="dia_item"><a href="http://www.craes.cn/" target="_blank">中国环境科学研究院</a></div>
          </div>
          <div class="dia_title" @mouseover="tabId=2" @mouseleave="tabId=-2">环保链接 <span>▲</span></div>
        </div>
        <div>
          <div @mouseover="tabId=3" @mouseleave="tabId=-1" class="dia_div2" v-show="tabId==3">
            <div class="dia_item"><a href="http://jnepb.jinan.gov.cn/" target="_blank">济南市生态环境局</a></div>
            <div class="dia_item"><a href="http://mbee.qingdao.gov.cn/" target="_blank">青岛市生态环境局</a></div>
            <div class="dia_item"><a href="http://epb.zibo.gov.cn/" target="_blank">淄博市生态环境局</a></div>
            <div class="dia_item"><a href="http://sthjj.zaozhuang.gov.cn/" target="_blank">枣庄市生态环境局</a></div>
            <div class="dia_item"><a href="http://sthj.dongying.gov.cn/" target="_blank">东营市生态环境局</a></div>
            <div class="dia_item"><a href="https://hbj.yantai.gov.cn/index.html" target="_blank">烟台市生态环境局</a></div>
            <div class="dia_item"><a href="http://sthjj.weifang.gov.cn/" target="_blank">潍坊市生态环境局</a></div>
            <div class="dia_item"><a href="http://jnhj.jining.gov.cn/" target="_blank">济宁市生态环境局</a></div>
            <div class="dia_item"><a href="http://sthjj.taian.gov.cn/index.html" target="_blank">泰安市生态环境局</a></div>
            <div class="dia_item"><a href="https://sthjj.weihai.gov.cn/" target="_blank">威海市生态环境局</a></div>
            <div class="dia_item"><a href="http://sthjj.rizhao.gov.cn/" target="_blank">日照市生态环境局</a></div>
            <div class="dia_item"><a href="http://hbj.linyi.gov.cn/" target="_blank">临沂市生态环境局</a></div>
            <div class="dia_item"><a href="http://dzbee.dezhou.gov.cn/" target="_blank">德州市生态环境局</a></div>
            <div class="dia_item"><a href="http://sthjj.liaocheng.gov.cn/" target="_blank">聊城市生态环境局</a></div>
            <div class="dia_item"><a href="http://hb.binzhou.gov.cn/" target="_blank">滨州市生态环境局</a></div>
            <div class="dia_item"><a href="http://hzsthj.heze.gov.cn/" target="_blank">菏泽市生态环境局</a></div>
          </div>
          <div class="dia_title" @mouseover="tabId=3" @mouseleave="tabId=-3">地方环保 <span>▲</span></div>
        </div>
        <div>
          <div @mouseover="tabId=4" @mouseleave="tabId=-1" class="dia_div1" v-show="tabId==4">
            <div class="dia_item"><a href="http://www.saes.com.cn/" target="_blank">山东省环境保护科学研究设计院有限公司</a></div>
            <div class="dia_item"><a href="http://www.shandongyf.com/" target="_blank">烟台云沣生态环境产业发展股份有限公司</a></div>
            <div class="dia_item"><a href="http://www.dayuchem.com/" target="_blank">山东大禹水处理有限公司</a></div>

          </div>
          <div class="dia_title" @mouseover="tabId=4" @mouseleave="tabId=-4">环境医院 <span>▲</span></div>
        </div>
        <div>
          <div @mouseover="tabId=5" @mouseleave="tabId=-1" class="dia_div" v-show="tabId==5">
            <div class="dia_item"><a href="https://www.sdcpxx.com/" target="_blank">山东三润环保科技有限公司</a></div>
            <div class="dia_item"><a href="https://sdhaiyue.net/" target="_blank">山东海岳环境科技股份有限公司</a></div>
            <div class="dia_item"><a href="http://www.lyhuanping.com/" target="_blank">临沂市环境保护科学研究所有限公司</a></div>
            <div class="dia_item"><a href="http://www.dzldgj.com/" target="_blank">德州蓝盾管家环保科技有限公司</a></div>
            <div class="dia_item"><a href="http://ljenvironment.com" target="_blank">山东鲁金环境工程有限公司</a></div>
            <div class="dia_item"><a href="http://www.xinkehjzx.cn/" target="_blank">山东省鑫科环境咨询有限公司</a></div>
            <div class="dia_item"><a href="http://www.jndzl.com/" target="_blank">济南东之林智能软件有限公司</a></div>
            <div class="dia_item"><a href="http://www.sddt100.com/" target="_blank">山东典图生态环境工程有限公司</a></div>
            <div class="dia_item"><a href="http://www.sdlzy.com.cn/" target="_blank">绿之缘环境产业集团有限公司</a></div>
          </div>
          <div class="dia_title" @mouseover="tabId=5" @mouseleave="tabId=-5">环保管家 <span>▲</span></div>
        </div>
      </div>
      <div class="text_div app_common_lb">
        <span>单位地址：山东省济南市凤凰南路汉峪金谷A7区1栋</span>
        <span>邮编：250101</span>
        <span>联系电话：0531-51799161</span>
        <!--        <span>传真：0531-51799164</span>-->
        <span>电子邮箱：zxb@sdaep.com</span>
        <hr>
        <span>鲁ICP备17019922号-3</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBar",
  data() {
    return {
      tabId: -1
    }
  }
}
</script>

<style scoped lang="scss">

.FooterBar {
  position: relative;
  bottom: 0;
  width: 100%;
  font-size: 11px;
  font-family: Microsoft YaHei;
  background-size: 100% 600%;
  background: url("~@/assets/images/index/banner1.jpg") 50% 63% no-repeat;

  .bg_p {
    color: #999999;
    background: rgba(243, 244, 245, 0.71);
    padding: 15px 0;

    .hid_div {
      width: 1200px;
      height: 50px;
      line-height: 50px;
      color: #000000;
      margin: 0 auto;
    }

    .flex_style {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
    }

    .dia_title {
      cursor: pointer;
      font-size: 18px;

      span {
        font-size: 12px;
      }
    }

    .dia_title:hover {
      color: #4c8ae5;
    }

    .dia_div {
      width: 1200px;
      left: calc((100% - 1200px) / 2);
      position: absolute;
      height: 200px;
      background-color: #4c8ae5;
      border-radius: 5px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
      font-size: 16px;
      line-height: 39px;
      color: #333333;
      margin-left: -20px;
      margin-top: -200px;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: space-between;
      z-index: 2;
    }

    .dia_div1 {
      width: 1200px;
      left: calc((100% - 1200px) / 2);
      position: absolute;
      height: 100px;
      background-color: #4c8ae5;
      border-radius: 5px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
      font-size: 13px;
      margin-left: -20px;
      margin-top: -100px;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: space-between;
      z-index: 2;
    }

    .dia_div2 {
      width: 1200px;
      left: calc((100% - 1200px) / 2);
      position: absolute;
      height: 300px;
      background-color: #4c8ae5;
      border-radius: 5px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
      font-size: 13px;
      margin-left: -20px;
      margin-top: -300px;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: space-between;
      z-index: 2;
    }

    .dia_item {
      color: #ffffff;
      width: 400px;
      height: 50px;
      line-height: 50px;
      padding-left: 40px;
      font-size: 17px;
    }

    .text_div {
      text-align: center;

      span {
        font-size: 16px;
        margin-right: 15px;
      }
    }
  }
}
</style>
